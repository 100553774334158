$(document).on("turbolinks:load", function() {
    $(".show_delete").first().addClass("d-none")
    showSelect2();
    
});


// Al dar click en la clase .new_transaction el campo que se genera debe tener la clase de select2
$(document).on("click", ".new_transaction", function() {
    //AGREGA LA CLASE select2_transaction AL CAMPO QUE SE GENERA
    let select2_transaction = $(".select2_transaction")
    select2_transaction.each(function(index) {
        if (index !== 0 && index !== select2_transaction.length - 1) {
            return; // Skip elements between the first and last
        }
        $(this).select2();
        showSelect2();
    });
});

const showSelect2 = () => {
    // FILTRO GENERAL PARA USAR DONDE SE REQUIERA
    $(".select2_transaction").select2({
        placeholder: "Indique el nombre",
        theme: "bootstrap-5",
        allowClear: true,
        width: '100%',
        language: {
        noResults: function() {
            return "No hay resultados";
        }
    }})
}