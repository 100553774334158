$(document).on("turbolinks:load", function(){
    let is_holding_tag = $("#is_holding")
    let branch = $(".company_branch")
    let show_holding_company = $(".show_company")
    holding_company()
    $(".company_branch").change(holding_company())

    // Hide or show holding company field
    is_holding_tag.val() === "true" || branch.val() === "9" ? show_holding_company.addClass("hidden") : show_holding_company.removeClass("hidden")

    // Hide or show company branch
    is_holding_tag.val() === "true" ? branch.addClass("hidden") : branch.removeClass("hidden")
    
    if(is_holding_tag){
        // Change holding company field when is holding company is checked
        is_holding_tag.change(function(){
            if(is_holding_tag.val() === "true"){
                $("#company_holding").val('')
                branch.val('')
                show_holding_company.addClass("hidden")
                branch.addClass("hidden")
            }else{
                show_holding_company.removeClass("hidden")
                branch.removeClass("hidden")
            }
        })
    }


})

const holding_company = () => {
    let show_holding_company = $(".show_company")
    $(".company_branch option:selected").text() === "Tercero" ? show_holding_company.hide() : show_holding_company.show()
    $(".company_branch").change(function(){
        if($(".company_branch option:selected").text() === "Tercero") {
            show_holding_company.hide()
        }
        else{
            show_holding_company.show()
            $("#company_holding").val('')
        }
    })
}