// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.


// require('jquery')
// import Rails from "@rails/ujs"
// import Turbolinks from "turbolinks"
// import * as ActiveStorage from "@rails/activestorage"
// import "channels"
// import $ from 'jquery'

// Rails.start()
// Turbolinks.start()
// ActiveStorage.start()


// No hace falta añadir Popper o jQuery aquí porque Bootstrap los llamará internamente
require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require('bootstrap')
require('bootstrap-datepicker')
require("@fortawesome/fontawesome-free/js/all")
// require("@nathanvda/cocoon")
require("select2")
require('packs/registration')
require('packs/edit_stockholder')
require('packs/search_stockstockholder')
require('packs/new_assembly')
require('packs/reports')
require('packs/visor_contraseña')
require('packs/edit_company')
require('packs/edit_bank_entity')
require('packs/edit_request')
require('packs/edit_transaction')
require('packs/edit_authorized')


//SPA DEPENDENCIES
import "controllers"
import { Turbo } from "@hotwired/turbo-rails"
import swal from 'sweetalert';
window.Swal = swal;
Turbo.session.drive = false
$(document).on('turbolinks:before-cache', function() {
  
  if(displaySelect2){
    displaySelect2.select2('destroy');
  }

  if(AdminUserDataTable){
    AdminUserDataTable.destroy()
  }

  if(StockholderDataTable){
    StockholderDataTable.destroy()
  }

  if(displayCompanyTable){
    displayCompanyTable.destroy()
  }

  if (ubos){
    ubos.destroy()
  }
})

$(document).on("turbolinks:load", function () {
    components_ready();
    // let browser = fnBrowserDetect();  
    // module.exports = {browser};
    showModal();
    showSelect2();
    showDataTableCompany();
    showDataTableStockholder();
    showDataTableAdminUser();
    showDataTableUBOs();
    LimitNumber();
    available_stocks();
    preventCopyPaste();
});

// EVENTO PARA REINICIAR EL SELECT 2 Y QUE NO SE DISPARE
document.addEventListener("turbolinks:before-visit", function() {

  if(AdminUserDataTable){
    AdminUserDataTable.destroy();
  }else{
    showDataTableAdminUser();
  }

  if(StockholderDataTable){
    StockholderDataTable.destroy();
  }else{
    showDataTableStockholder();
  }

  if(displayCompanyTable){
    displayCompanyTable.destroy();
  }else{
    showDataTableCompany();
  }
})

$.fn.datepicker.dates['es'] = {
    days: ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"],
    daysShort: ["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sab"],
    daysMin: ["D", "L", "M", "X", "J", "V", "S"],
    months: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
    monthsShort: ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"],
    today: "Hoy",
    clear: "Limpiar",
    format: "dd-mm-yyyy",
    titleFormat: "MM yyyy", /* Leverages same syntax as 'format' */
    weekStart: 0,
};

function fnBrowserDetect(){
                 
    let userAgent = navigator.userAgent;
    let browserName;
    
    if(userAgent.match(/chrome|chromium|crios/i)){
        browserName = "chrome";
      }else if(userAgent.match(/firefox|fxios/i)){
        browserName = "firefox";
      }  else if(userAgent.match(/safari/i)){
        browserName = "safari";
      }else if(userAgent.match(/opr\//i)){
        browserName = "opera";
      } else if(userAgent.match(/edg/i)){
        browserName = "edge";
      }else{
        browserName="No browser detection";
      }
      return browserName;
}

function components_ready(){
    let date = new Date()
    let month = date.getMonth() == 11 ? 0 : date.getMonth() + 1
    let year = month == 0 ? date.getFullYear() + 1 : date.getFullYear()
    $('.calendar-full-month').datepicker({
        format: 'dd-mm-yyyy',
        endDate: new Date(year, month, 0), // Asi seleccionamos hasta el último día del mes
        language: 'es',
    })

    $('.calendar').datepicker({
        format: 'dd-mm-yyyy',
        endDate: '-1d',
        language: 'es',
    });

    $('.company-calendar').datepicker({
      format: 'dd-mm-yyyy',
      // EL DIA INICIAL DEBE SER MAYOR AL DÍA ACTUAL
      startDate: '+1d',
      language: 'es',
    })

    $('.company-establishment-calendar').datepicker({
      format: 'dd-mm-yyyy',
      // EL DIA INICIAL DEBE SER MAYOR AL DÍA ACTUAL
      endDate: '-1d',
      language: 'es',
    })

    $('.assembly-calendar').datepicker({
        format: 'dd/mm/yyyy',
        startDate: new Date(),
        language: 'es',
    });

    $('.report-calendar').datepicker({
      format: 'dd-mm-yyyy',
      endDate: new Date(),
      startDate: new Date('2022-04-13'), // Día inicial 12-04-2022
      daysOfWeekDisabled: '0,6',
      language: 'es',
  });

    $('.poll-calendar').datepicker({
      format: 'dd-mm-yyyy',
      startDate: new Date(), 
      daysOfWeekDisabled: '0,6',
      endDate: "+1y",
      language: 'es'
    })

    $('.solvency_date').datepicker({
      format: 'dd-mm-yyyy',
      endDate: new Date(),
      language: 'es',
    })

    $(".document-expiration").datepicker({
      format: 'dd-mm-yyyy',
      language: 'es'
    })

    $(".cert_date").datepicker({
      format: 'dd-mm-yyyy',
      language: 'es',
      endDate: new Date()
    })

    $(".stock_purchase_date").datepicker({
      format: 'dd-mm-yyyy',
      language: 'es',
      endDate: new Date()
    })

    $(".authorized_end_date").datepicker({
      format: 'dd-mm-yyyy',
      language: 'es',
      startDate: new Date(),
    })
}

const showModal = () => {
  if ($("#exampleModal").length > 0){
      $("#exampleModal").modal('show')
  }
}

const showSpinner = () => {
  document.querySelector('form').addEventListener('submit', function(event) {
  document.getElementById('spinner').style.display = 'block';
  });
}

const truncate_document_string = () => {
  document.getElementById("assembly_document").addEventListener("change", function() {
    var fileName = this.files[0].name;
    if (fileName.length > 30) {
      fileName = fileName.substring(0, 30) + '...';
    }
    document.querySelector(".file-name").textContent = fileName;
  }); 
}

const enable_button = () => {
  document.querySelector("#assembly_document").addEventListener("change", function() {
    document.querySelector("#submit-button").removeAttribute("disabled");
  });
}

// No scroll cuando recargo la página
$(window).on("scroll",function () {
    sessionStorage.scrollTop = $(this).scrollTop();
});
$(document).on("ready",function () {
    if (sessionStorage.scrollTop != "undefined") {
        $(window).scrollTop(sessionStorage.scrollTop);
    }
});

// Barra de Progreso para carga de archivos
addEventListener("direct-upload:initialize", event => {
  const { target, detail } = event
  const { id, file } = detail
  target.insertAdjacentHTML("beforebegin", `
    <div id="direct-upload-${id}" class="direct-upload direct-upload--pending">
      <div id="direct-upload-progress-${id}" class="direct-upload__progress" style="width: 0%"></div>
      <span class="direct-upload__filename text-dark fw-bold">${file.name}</span>
    </div>
  `)
  $("#paperclip").hide()
})
addEventListener("direct-upload:start", event => {
  const { id } = event.detail
  const element = document.getElementById(`direct-upload-${id}`)
  element.classList.remove("direct-upload--pending")
})
addEventListener("direct-upload:progress", event => {
  const { id, progress } = event.detail
  const progressElement = document.getElementById(`direct-upload-progress-${id}`)
  progressElement.style.width = `${progress}%`
})
addEventListener("direct-upload:error", event => {
  event.preventDefault()
  const { id, error } = event.detail
  const element = document.getElementById(`direct-upload-${id}`)
  element.classList.add("direct-upload--error")
  element.setAttribute("title", error)
})
addEventListener("direct-upload:end", event => {
  const { id } = event.detail
  const element = document.getElementById(`direct-upload-${id}`)
  element.classList.add("direct-upload--complete")
})


	// spinner.js
	document.addEventListener('turbolinks:click', function () {
    // Mostrar el spinner al hacer clic en un enlace o enviar un formulario
      document.getElementById('spinner').classList.remove('hidden');
      });
    
    document.addEventListener('turbolinks:before-visit', function () {
      // Mostrar el spinner antes de cargar una nueva página
      document.getElementById('spinner').classList.remove('hidden');
    });
    
    document.addEventListener('turbolinks:load', function () {
      // Ocultar el spinner cuando la página ha terminado de cargar
      document.getElementById('spinner').classList.add('hidden');
    });
    
      // Mostrar el spinner al recargar la página
    window.addEventListener('beforeunload', function () {
      document.getElementById('spinner').classList.remove('hidden');
    });

    let displaySelect2
    const showSelect2 = () => {
      // FILTRO GENERAL PARA USAR DONDE SE REQUIERA
      displaySelect2 = $(".select2_filter").select2({
        placeholder: "Indique el nombre",
        theme: "bootstrap-5",
        allowClear: true,
        width: '100%',
        language: {
          noResults: function() {
            return "No hay resultados";
          }
      }})
    }

    let displayCompanyTable
    const showDataTableCompany = () => {
      // SE RECOGE EL ID DE LA EMPRESA ACTUAL
      const companyId = $(".get-table-id").attr("id");
      // SE SELECCIONA LA TABLA UTILIZANDO EL ID DINÁMICO
      const companyMovementTable = $(`#${companyId}`);

      // SI LA TABLA EXISTE, SE CREA EL OBJETO DATATABLE
      displayCompanyTable = companyMovementTable.DataTable({
        responsive: true,
        order: [[1, 'asc']],
        language: {
          url: "//cdn.datatables.net/plug-ins/2.0.7/i18n/es-ES.json"
        },
      });
    };
    

    let StockholderDataTable
    const showDataTableStockholder = () => {
      // SE INSTANCIAS LOS OBJETOS NECESARIOS PARA DATATABLE
      StockholderDataTable = $('#stockholderslist').DataTable({
        order: [[1, 'asc']],
        responsive:true,
        language: {
          url: "//cdn.datatables.net/plug-ins/2.0.7/i18n/es-ES.json"
        },
      });
    }

    let AdminUserDataTable
    const showDataTableAdminUser = () => {
      AdminUserDataTable = $('#admin_list').DataTable({
        responsive:true,
        language: {
          url: "//cdn.datatables.net/plug-ins/2.0.7/i18n/es-ES.json"
        },
      })
    }

    let ubos
    const showDataTableUBOs = () => {
      ubos = $('#ubos').DataTable({
        responsive:true,
        language: {
          url: "//cdn.datatables.net/plug-ins/2.0.7/i18n/es-ES.json"
        },
      })
    }

    const LimitNumber = () => {
      $(".limit-number").on('input', function(evt) {
        const value = evt.target.value;

        // Limit to 11 digits (including decimal point)
        if (value.length > 11) {
          evt.target.value = value.slice(0, 11); // Truncate to 11 characters
        }
      });
    };

    //OBTENER ACCIONES DISPONIBLES MEDIANTE AJAX
    function getAvailableStocks(company_id) {
      return $.ajax({
        url: '/companies/get_available_stocks/' + company_id,
        method: 'GET',
      });
    }
    
    const available_stocks = () => {
      //OCULTAR ALERTA
      $(".show_alert_stocks").addClass('hidden');
      $("#company_select").on("change", function(e) {
        let company_id = e.target.value;
        
        getAvailableStocks(company_id).then((response) => {
          // Extract available stock quantity from response
          let availableStockQuantity = response || 0; // Assuming "available_stocks" is the key in the response object
          $(".show_alert_stocks").removeClass('hidden');
          $(".show_existing_alert_stocks").addClass('hidden');
          // Update alert content with available stock quantity
          $(".alert-warning span").text(availableStockQuantity);
        }).catch((error) => {
          alert("Error fetching available stocks");
        });
      });
    };

    const preventCopyPaste = () => {
      const emailInput = document.getElementById('email_confirmation');

      if(emailInput){
        emailInput.addEventListener('copy', (event) => {
          event.preventDefault(); // Prevent copying the text
        });
  
        emailInput.addEventListener('paste', (event) => {
          event.preventDefault(); // Prevent pasting text
        });
      }

    }