
$(document).on('turbolinks:load', function(){
    div_foreign_number();
    div_type_user();
    focus_document();
    phone_number();
    show_sucessoral_fields();
    // change_primary_email();
    $(".show_delete").first().addClass("d-none")
    //show_deceased_date();
    $("#person_type").change(div_type_user)
    $("#is_succession").change(show_sucessoral_fields)
    // $("#foreign").change(div_foreign_number);
    
    const {browser} = require('./application.js');
    let delay;
    if (browser === "firefox" || browser === "safari"){
        delay = 100;
    } else {
        delay = 600;
    }

    let id = document.getElementById('stockholder_id');
    let autofocusedElements = document.querySelector('#stockholder_phone_region');
    if (id && id.value !== "") {
        if (autofocusedElements) {
            setTimeout( function() {
                if (browser === "firefox" || browser === "safari"){
                    autofocusedElements.scrollIntoView({behavior: "smooth", block: "center"});
                } else {
                    autofocusedElements.focus();
                }
            }, delay);
        } else {
            autofocusedElements = document.querySelector('#stockholder_email_email');
            if (autofocusedElements) {
                setTimeout( function() {
                    if (browser === "firefox" || browser === "safari"){
                        autofocusedElements.scrollIntoView({behavior: "smooth", block: "center"});
                    } else {
                        autofocusedElements.focus();
                    }
                }, delay);
            }
        }
    }
});

function div_foreign_number() {
    if($("#foreign:checked").val()){
        $('.foreign_field').show();
    }
    else{
        $('.foreign_field').hide();
    }
}

function div_type_user() {
    if($("#person_type option:selected").text() === "Natural"){
        $('.natural_person').show();
        $('.juridical_person').hide()
        $('.successoral_fields').hide()
        // SET NAME MANDATORY
        $("#stockholder_person_attributes_first_name").prev().text("Nombre *")
        // CLEAR JURIDICAL FIELDS
        $(".legal_id").val('');
        $('.legal_id').val(null).trigger('change');

    }
    else if($("#person_type option:selected").text() === "Juridico"){
        $('.natural_person').hide();
        $('.juridical_person').show()
        $('.juridical_name').text('Nombre de la empresa');
        $("#is_succession option:selected").text() === "SI" ? $('.successoral_fields').show() : $('.successoral_fields').hide();

        // CLEAR NATURAL FIELDS
        $("#stockholder_person_attributes_document").val('');
        $("#stockholder_person_attributes_document_expiration_date").val('');
        $("#stockholder_person_attributes_first_surname").val('');
        $("#stockholder_person_attributes_second_name").val('');
        $("#stockholder_person_attributes_second_surname").val('');
        $("#stockholder_marital_status").val('');
        $("#stockholder_profession").val('');
        $("#stockholder_passport").val('');
        //SET NAME
        $("#stockholder_person_attributes_first_name").prev().text("Razón Social *")
    }
    else{
        $('.natural_person').hide();
        $('.juridical_person').show();
    }
}

const focus_document = () => {
    const text = 'Documento eliminado con éxito';

    for (const div of document.querySelectorAll('div')) {
      if (div.textContent.includes(text)) {
         document.getElementById("document_table").scrollIntoView()
      }
    }    
}

const phone_number = () => {
    //allow only to type numbers
    const input = document.getElementById('stockholder_phone_attributes_number');
    if(input){
        input.addEventListener('input', function() {
            this.value = this.value.replace(/[^0-9]/g, '');
        });
    }
    
}

const show_deceased_date = () => {
    // Mostrar el input "deceased_date" si "deceased" está marcado al cargar la página
    if ($("#deceased").is(":checked")) {
      $("#deceased_date").show();
    } else {
      $("#deceased_date").hide();
    }
  
    // Escuchar los cambios en el input "deceased"
    $("#deceased").change(function() {
      if ($(this).is(":checked")) {
        // Mostrar el input "deceased_date" si "deceased" está marcado
        $("#deceased_date").show();
      } else {
        // Ocultar el input "deceased_date" si "deceased" no está marcado
        $("#deceased_date").hide();
      }
    });
  }

const show_sucessoral_fields = () => {
    let successoral_fields = $(".successoral_fields")
    // ESCUCHA LOS CAMBIOS EN EL SELECT DE IS_SUCCESSION
    $("#is_succession option:selected").text() === "SI" ? $('.successoral_fields').show() : $('.successoral_fields').hide();

    //CUANDO SE CAMBIE A NO LIMPIA LOS CAMPOS
    if ($("#is_succession option:selected").text() === "NO"){
        successoral_fields.find("input[type!='hidden'], select, textarea").val("");
    }

}