$(document).on('turbolinks:load', function (){
    displaySetPeriodFields()
    $("#set_period").change(displaySetPeriodFields)
})

const displaySetPeriodFields = () => {
    if($("#set_period option:selected").text() === "SI"){
        $('.set_period_fields').show();
    }
    else{
        $('.set_period_fields').hide()
        //LIMPIAR SET PERIOD FIELDS
        $('.set_period_fields').find("input[type!='hidden'], select, textarea").val("");
    }
}
